<template>
  <div>
    <div class="float-right p-2">
      <button
        :class="isActive == 'week' ? 'btn-primary' : 'btn-default'"
        class="btn mr-2"
        type="button"
        @click="activeState('week')"
      >
        Last 15 Days
      </button>
      <button
        class="btn mr-2"
        :class="isActive == 'month' ? 'btn-primary' : 'btn-default'"
        type="button"
        @click="activeState('month')"
      >
        Month
      </button>
      <button
        class="btn"
        :class="isActive == 'year' ? 'btn-primary' : 'btn-default'"
        type="button"
        @click="activeState('year')"
      >
        Year
      </button>
    </div>
    <CChartBar class="d-print-none" :datasets="newChartData" :labels="chartLabels" :options="{ scales:{ yAxes: [{ticks: { beginAtZero: true } }] } }" />
    <img v-if="canvasUrl" :src="canvasUrl" alt="consumption chart" class="d-none d-print-block w-100">
  </div>
</template>

<script>
import { CChartBar } from "@coreui/vue-chartjs";
import { mapGetters } from "vuex";

export default {
  name: "ConsumptionBarChartMeterWise",
  components: { CChartBar },
  props: {
    meterId: Number,
    printPage: Boolean
  },
  data() {
    return {
      canvasUrl: null,      
      isActive: "week",
    };
  },
  computed: {
    ...mapGetters("Dashboard", ["newChartData", "chartLabels"]),
  },

  methods: {
    activeState(state) {
      this.isActive = state;
      this.$store.dispatch("Dashboard/getMeterConsumptionsDataByMeter", {meterId: this.meterId, stateData: state});
    },
  },
  mounted() {
    this.$store.dispatch("Dashboard/getMeterConsumptionsDataByMeter", {meterId: this.meterId, stateData: this.isActive});   
  },
  watch: {
    "printPage"() {
        let canvas = document.querySelector('canvas');
        this.canvasUrl = canvas.toDataURL();        
    }
},
};
</script>


